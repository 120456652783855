html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pre-wrap {
  white-space: pre-wrap;
}

.hover-higlight:hover {
  background-color: rgb(238, 238, 238);
}

.search-btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  border-bottom: 1px solid grey;
}

.node-row {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 0.9em;
  cursor: pointer;
  white-space: nowrap;
  color: #000000;
  background-color: #fff;
}

.node-row:hover {
  /* background-color: #e6e6e6 !important; */
  filter: brightness(0.95);
  font-weight: bold;
}

.no-hover-bolding {
  font-weight: normal !important;
}

.clickable-tree-node {
  display: flex;
  align-items: center;
  height: 100%;
}

.node-icon {
  display: flex;
  padding-right: 6px;
  color: rgb(70, 50, 255);
}

.draft-color {
  color: #ed8113;
}

.left-padding-4 {
  padding-left: 4px;
}

.path-text {
  display: flex;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  white-space: nowrap;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 4px;
}

.font-bold {
  font-weight: bold;
}

.bg-blue-300 {
  background-color: #87b9ff;
}

.cursor-pointer {
  cursor: pointer;
}

.path-text:hover {
  background-color: #e6e6e6 !important;
}

.path-icon {
  padding-left: 6px;
  padding-right: 6px;
}

.no-link-style {
  text-decoration: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-align-center {
  align-items: center;
  justify-content: center;
}

.navigation {
  position: relative;
  display: inline-block;
}
.version-menu {
  width: 120px;
}
.nav-menu {
  width: 130px;
}
.navigation-content-body {
  border-color: rgb(144, 209, 151);
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  padding: 8px;
}
.version-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  color: #000;
  background-color: rgb(144, 209, 151);
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
}
.navigation-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 35px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}
.navigation-item {
  color: #000;
  padding: 8px;
  margin: 0;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.navigation-item:hover {
  background-color: rgb(144, 209, 151);
  border-radius: 4px;
  color: #000;
}
.version-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
table {
  border-collapse: collapse;
  border-style: none;
  table-layout: fixed;
  width: 100%;
}

th,
td {
  height: 20px;
  min-height: 20px;
  font-size: 12px;
  font-weight: 600;
  font-family: Calibri, sans-serif;
  background-color: #90d197;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
  border: none;
  box-shadow: 0 1px grey inset, 0 -1px grey inset, -1px 0 grey inset,
    1px 0 grey inset;
  position: relative;
  /* white-space: nowrap; */
  /* border: 1px solid grey; */
}

.table-cell-input {
  /* position: absolute;
  top: 1px;
  left: 1px;
  height: calc(100% - 4px);
  width: calc(100% - 7px); */
  padding-left: 2px;
  padding-right: 2px;
  height: 100%;
  width: 100%;
  border-style: none;
  font-size: 12px;
  font-weight: 600;
  font-family: Calibri, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: inherit;
}

.table-cell-input:focus {
  outline: none;
}

.font-size-21 {
  font-size: 21px;
}

.text-padding-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.text-padding-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: -3px;
  width: 7px;
  height: 40px;
  cursor: col-resize;
  user-select: none;
  z-index: 10;
}

.resizer:hover,
.resizing {
  right: -2px;
  top: -1px;
  background-color: rgb(162, 162, 162);
}

.expandIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border: 1px solid grey;
  border-radius: 3px;
  margin-right: 6px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.tree-toolbar-button {
  height: 28px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 3px;
}

.tree-toolbar-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-node {
  background-color: #dedede;
}

.GridRow {
  position: relative;
  display: flex;
  flex-direction: row;
}

.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.LeftSideGridContainer {
  flex: 0 0 40px;
  /* width: 40px; */
  z-index: 10;
}

.LeftSideGrid {
  overflow: hidden !important;
  outline: none;
}

.HeaderGrid {
  width: 100%;
  overflow: hidden !important;
  outline: none;
}

.BodyGrid {
  width: 100%;
  outline: none;
}

.grid-input {
  width: 100%;
  height: 100%;
  outline: none;
  border-style: none;
  font-size: inherit;
  /* font-weight: inherit; */
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: inherit;
  padding: 0;
  padding-left: 1px;
}

.grid-cell {
  font-size: 11px;
  font-family: Calibri, sans-serif;
  width: 100%;
  height: 100%;
  background-color: rgb(144, 209, 151);
  border: 1px solid grey;
  /* box-shadow: 0 1px grey inset, 0 -1px grey inset, -1px 0 grey inset,
    1px 0 grey inset; */
}

.input-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 17px;
  font-size: 11px;
  font-family: inherit;
  color: #fff;
  font-weight: bold;
  background-color: #446fb5;
  z-index: 100000;
  position: fixed;
}

.indicator-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.indicator-cell:hover {
  border-width: 2px;
}

.product-cell {
  background-color: #fff;
}

.highlighted-cell {
  background-color: #f5bf42 !important;
}

.selected-cell {
  /* filter: brightness(1.1); */
  background-color: #c5dbff !important;
  /* background-color: #63b06b !important; */
}

.copied-cell {
  background-color: #93bcff;
}

.missing-id {
  background-color: #f084a1 !important;
}

.overflow-cell {
  background-color: #c7c7c7;
  /* filter: brightness(1.4); */
}

.header {
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: #303030;
  z-index: 1000;
  padding-right: 0;
  /* overflow: hidden; */
}

.overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1100;
}

.invisible {
  background-color: transparent !important;
}

.block {
  display: block;
}

.popover {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1101;
  max-width: 600px;
}

.transparent {
  opacity: 0;
}

.arrow {
  position: absolute;
  background: #fff;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

/*** Overrides for react-virtualized styles ***/
.ReactVirtualized__Table__rowColumn {
  margin-right: 0 !important;
  margin-left: 0 !important;
  /* border: 1px solid #000; */
  box-shadow: 0 1px black inset, 0 -1px black inset, -1px 0 black inset,
    1px 0 black inset;
  height: 100%;
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table {
  display: inline-block;
  box-shadow: 0 1px black inset, 0 -1px black inset, -1px 0 black inset,
    1px 0 black inset;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 2px;
  padding-right: 2px;
  /* margin-right: 10px;
  margin-left: 10px; */
}

.ReactVirtualized__Table__headerRow {
  background-color: #303030;
  color: #fff;
  box-shadow: 0 1px black inset, 0 -1px black inset, -1px 0 black inset,
    1px 0 black inset;
}

.ReactVirtualized__Table__headerTruncatedText {
  flex: auto;
  position: relative;
}

.DragHandle {
  /* flex: 0 0 16px; */
  z-index: 2;
  cursor: col-resize;
  color: rgb(144, 209, 151);
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: rgb(160, 201, 164);
  z-index: 3;
}

.DragHandleIcon {
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom_flexlayout__tab_toolbar_button {
}

.custom_flexlayout__tabset-selected {
  background-color: #b6b6b6 !important;
}

.Toastify__toast-body {
  white-space: pre-line;
}
